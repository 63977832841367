<template>
  <div class="quote-table__item">

    <div class="quote-table__header-item"> Item </div>
    <div class="quote-table__row-item">{{ product.id }}</div>

  </div>

  <div class="quote-table__item">

    <div class="quote-table__header-item"> No. parte </div>

    <div class="quote-table__row-item position-relative">

      <v-select v-model="product.rfp_customer_title"
                class="form-input w-100"
                :class="{error2: errors.rfp_customer_title?.$error, 'cali__login-error': errors.rfp_customer_title?.$error}"
                :options="products"
                :clearable="false"
                label="main_title"
                :reduce="p => p.sku"
                :loading="status.products.isBusy"
                :disabled="disabled"
                @search="getProducts"
      >
        <template #open-indicator="{ attributes }">
          <img src="https://tba.mints.cloud/public-assets/down-arrow" alt="" v-bind="attributes">
        </template>

        <template #no-options>
          Ingresa el no. parte
        </template>
      </v-select>

      <i class="fa fa-seal-exclamation" v-if="product.isNewProduct" title="Producto no reconocido, ingresa una descripción"></i>
    </div>

  </div>

  <div class="quote-table__item">

    <div class="quote-table__header-item"> Descripción </div>
    <div class="quote-table__row-item" :title="product.isNewProduct ? 'Producto no reconocido, ingresa una descripción' : ''">
      <input type="text"
             :title="product.isNewProduct ? 'Producto no reconocido, ingresa una descripción' : ''"
             :placeholder="product.isNewProduct ? 'Producto no reconocido, ingresa una descripción' : ''"
             v-model="product.rfp_customer_description"
             class="form-control custom-placeholder-color"
             :disabled="disabled"
             :class="{error2: errors.rfp_customer_description?.$error, 'cali__login-error': errors.rfp_customer_description?.$error}"
      />
    </div>

  </div>

  <div class="quote-table__item">

    <div class="quote-table__header-item"> Condición </div>
    <div class="quote-table__row-item">
      <select v-model="product.product_condition" class="form-control"
              style="appearance: auto !important;"
              :disabled="disabled"
              :class="{error: errors.product_condition?.$error}">
        <option v-for="condition in product_condition" :value="condition" :key="condition"> {{ condition }}</option>
      </select>
    </div>

  </div>

  <div class="quote-table__item">

    <div class="quote-table__header-item">Cantidad </div>
    <div class="quote-table__row-item">
      <input type="number"
      class="form-control"
      v-model="product.quantity"
      :disabled="disabled"
      :class="{error: errors.quantity?.$error, 'cali__login-error': errors.quantity?.$error}"
      @change="qtyChanged">
    </div>

  </div>

  <div class="quote-table__item" style="flex: 5;">

    <div class="quote-table__header-item d-flex gap-2 position-relative">
      <p class="position-absolute text-white p-3 referencePriceTooltip"
         v-show="showReferencePrice"
         style="white-space: inherit;"
         :class="[showReferencePrice ? 'd-block' : 'd-none']">
        Incluir un precio de referencia agiliza nuestra búsqueda y expectativa presupuestaria
      </p>
      <span>Precio de referencia unitario (Opcional)</span>
      <span class="btnReferencePrice mt-1"
            @click="showReferencePrice = !showReferencePrice"
            @mouseover="showReferencePrice=true"
            @mouseleave="showReferencePrice=false">?</span>
    </div>
    <div class="quote-table__row-item" :class="{error2: errors.customer_reference_price?.$error }" style="min-width: 15em">
      <VueNumeric
          name="amount"
          :currency="'$'"
          :separator="','"
          :precision="2"
          :min="'0'"
          v-model="product.customer_reference_price"
          :disabled="disabled"
          inputClass="form-control"/>
    </div>
  </div>

  <div class="quote-table__item">
    <div class="quote-table__header-item">
    </div>
    <div class="quote-table__row-item">
      <a @click.prevent="remove">
        <i class="fa fa-trash ms-3" style="cursor: pointer"></i>
      </a>
    </div>

  </div>
</template>

<script>
import VueNumeric from '@mints-components/cali-numeric/App.vue';
import ProductWatcher from "@/customer-journey/create-quote/components/product_watcher";

export default {
  components: {VueNumeric},
  emits: ['update:modelValue', 'remove'],
  mixins: [ProductWatcher],

  props: {
    modelValue: {
      type: Object,
      required: true
    },
    errors: Object,
    disabled: Boolean
  },

  data: () => ({
    product_condition: ['Nuevo', 'Refurbished', 'Refurbished by Cisco', 'Ambas', 'Indistinta'],
    showReferencePrice: false
  }),

  computed: {
    product: {
      get() {
        return this.modelValue
      },

      set(newValue) {
        this.$emit('update:modelValue', newValue)
      }
    },
    subtotal() {
      return this.product.customer_reference_price * this.product.quantity
    },
    formatter() {
      return new Intl.NumberFormat('en-US', {
        style: 'currency',
        currency: 'USD',

        // These options are needed to round to whole numbers if that's what you want.
        minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
        maximumFractionDigits: 2, // (causes 2500.99 to be printed as $2,501)
      })
    }
  },

  methods: {
    qtyChanged() {
      if (this.product.quantity < 1) {
        this.product.quantity = 1
      }
    },

    remove() {
      this.$emit('remove', this.product.id)
    }
  },

  mounted() {
    if (this.product.rfp_customer_title != null) {      
      this.requestProducts(this.product.rfp_customer_title).then(() => {
        this.updateDescription(this.product.rfp_customer_title)
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.vs__selected {
  text-overflow: ellipsis;
  max-width: 192px;
  white-space: nowrap;
  overflow: hidden;
}
.error2 input {
  border-color: red;
}

.btnReferencePrice{
  display:flex;
  justify-content:center;
  align-items:center;
  background-color: black;
  border-radius: 50%;
  color: white;
  width: 15px;
  height: 15px;
  font-size: 12px;
}

.referencePriceTooltip{
  background-color: rgba(110,113,130,0.9);
  width: 300px;
  height: auto;
  top: -90px;
  font-size: 16px;
  z-index: 10000;
  white-space: wrap;
}


.fa-seal-exclamation {
  color: #0dcaf0;
  position: absolute;
  left: 2px;
  top: 0;
}
</style>

<style lang="scss">
.error2 {
  border-color: red !important;

  input {
    border-color: red !important;
  }
}

.fa-seal-exclamation{
  color: orange !important
}

.custom-placeholder-color::placeholder{
  color: red;
  opacity: 1; /* Firefox */
}
</style>