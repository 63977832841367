import debounce from "lodash/debounce";

export default {
    inject: ['$axios', 'locale'],

    data: () => ({
        status: { products: { isBusy: false } },
        products: [],
        productSearch: ''
    }),

    watch: {
        productSearch: {
            handler: debounce(async function (newValue) {
                let length = newValue?.trim()?.length || 0
                if (length > 2) {
                    await this.requestProducts(newValue);
                }
            }, 250)
        },

        'product.rfp_customer_title': {
            handler: debounce(function (newValue) {
                this.updateDescription(newValue)
            }, 200)
        }
    },

    methods: {
        getProducts(e) { this.productSearch = e },

        async getOptions(api, filters) {
            const response = await this.$axios({
                method: 'get',
                url: `${api}?${filters}`,
                baseURL: ""
            })
            return response.data.data
        },

        async requestProducts(search) {
            if ((search || '').trim().length < 1) return;

            this.status.products.isBusy = true;

            let products = await this.getOptions(
                '/api/v1/profile/products',
                `sku=${search}&lang=${this.locale}`
            );

            if (products.length > 0) {
                products.forEach(p => {
                    p.sku = p['relationships']?.product?.['skus']?.[0]?.sku
                    p.main_title = `${p.sku} - ${p.title}`
                });
            } else {
                products = [{ sku: search, relationships: {}, main_title: search }]
            }

            this.products = products
            this.status.products.isBusy = false;
        },

        updateDescription(payload) {
            const version = this.products.find(p => p.sku === payload)
            const product = version?.relationships?.product
            if (!this.product.rfp_customer_description) {
                this.product.rfp_customer_description = version?.title
            }

            if (product) {
                version.main_title = product.sku_prefix
                this.product.sku_id = product.skus[0].id
                this.product.product_id = product.id
                this.product.isNewProduct = false
            } else {
                this.product.isNewProduct = true
                this.product.sku_id = null
                this.product.product_id = null
            }
        }
    }
}